var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('general.change_time'),"no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.time_event_work')}},[_c('validation-provider',{attrs:{"name":_vm.$t('employee_time.time_event_work'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.EmployeeTimeEvens(),"reduce":function (x) { return x.value; },"clearable":false},model:{value:(_vm.event.event_type),callback:function ($$v) {_vm.$set(_vm.event, "event_type", $$v)},expression:"event.event_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('general.date_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.date_time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"first-day-of-week":2,"time-accuracy":"3","popover":_vm.popoverOptions,"masks":{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'},"mode":"dateTime","is24hr":true,"hide-time-header":true,"is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":_vm.$dayjs(inputValue).format('DD.MM.YYYY, HH:mm:ss')}},inputEvents))]}}],null,true),model:{value:(_vm.event.timestamp),callback:function ($$v) {_vm.$set(_vm.event, "timestamp", $$v)},expression:"event.timestamp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.add}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }