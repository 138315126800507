<template>
    <b-modal :title="$t('general.change_time')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group v-if="event" :label="$t('general.date_time')">
                    <validation-provider #default="{ errors }" :name="$t('general.date_time')"
                                         :rules="rules">
                        <date-picker :first-day-of-week="2" v-model="event.timestamp" :time-accuracy="3" :popover="popoverOptions"
                                     :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" mode="dateTime"
                                     :is24hr="true" :hide-time-header="true" :is-required="true"
                                     :min-date="event.prev_time_event ? event.prev_time_event.timestamp : null"
                                     :max-date="event.next_time_event ? event.next_time_event.timestamp : null">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="$dayjs(inputValue).format('DD.MM.YYYY, HH:mm:ss')"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="edit">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {DatePicker} from 'v-calendar'
    import {required, dateTimeBefore, dateTimeAfter} from '@validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BModal,
            BButton,
            DatePicker,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            employeePermissionGroups: {
                type: Array
            }
        },
        data() {
            return {
                modalActive: false,
                event: null,
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                popoverOptions: {
                    positionFixed: true
                },
                required,
                dateTimeBefore,
                dateTimeAfter
            }
        },
        methods: {
            open(data) {
                this.event = JSON.parse(JSON.stringify(data))
                this.modalActive = true
            },
            async edit() {
                try {
                    const valid = await this.$refs.validation.validate()
                    if (!valid) return

                    const payload = {
                        employee_id: this.event.employee_id,
                        timestamp: this.event.timestamp
                    }

                    await this.$http.put(`/api/client/v1/employees/time_events/${this.event.id}`, payload)
                    this.modalActive = false
                    this.$emit('itemEdited')
                    this.$printSuccess(this.$t('general.edit_success'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('general.error_on_edit'))
                }
            }
        },
        computed: {
            rules() {
                const rule = {required: true}
                if (this.event && this.event.next_time_event) rule['date_time_before'] = this.event.next_time_event.timestamp
                if (this.event && this.event.prev_time_event) rule['date_time_after'] = this.event.prev_time_event.timestamp
                return rule
            }
        }
    }
</script>
